import { ClaspEmployeeConfig, ClaspEmployerConfig } from "@/types";
import {
  CoverageType,
  LineOfCoverage,
  MemberRelationship,
  PayRatePeriod,
} from "@/types/api";
import { MRT_TableOptions } from "mantine-react-table";

export const DEFAULT_CONFIG: ClaspEmployerConfig = {
  navigation: "state",
  apiClient: "sandbox",
  employerId: "employer_123",
  onNavigate: (to: string) => {
    window.history.pushState({}, "", to);
  },
  links: {
    home: "/",
    editUser: "/",
  },
  auth: () => ({ accessToken: "", expiresAt: "" }),
};

export const DEFAULT_CONFIG_EE: ClaspEmployeeConfig = {
  navigation: "state",
  apiClient: "sandbox",
  employeeId: "ee_123",
  onNavigate: (to: string) => {
    window.history.pushState({}, "", to);
  },
  links: {
    home: "/",
    editUser: "/",
  },
  auth: () => ({ accessToken: "", expiresAt: "" }),
};

export const DEFAULT_TABLE_PROPS: Partial<MRT_TableOptions<any>> = {
  enableTopToolbar: false,
  enableBottomToolbar: false,
  enableColumnActions: false,
  enableSorting: false,
  mantineTableHeadCellProps: {
    p: "xs",
    style: {
      textTransform: "capitalize",
    },
  },
  mantineTableBodyCellProps: {
    p: "xs",
  },
  mantineTableBodyRowProps: {
    style: {
      "&:hover": {
        // backgroundColor: '#F5F5F5'
      },
    },
  },
  mantineSearchTextInputProps: {
    variant: "default",
  },
  initialState: {
    showGlobalFilter: true,
  },
};

export const states = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
] as const;

// Ancillary lines of coverage that are not Medical/Dental/Vision
export const SUPPLEMENTAL_LOCS = [
  "life",
  "accidental_death",
  "voluntary_life",
  "voluntary_critical_illness",
  "accident",
  "cancer",
  "short_term_disability",
  "voluntary_short_term_disability",
  "long_term_disability",
  "hospital_indemnity",
] as LineOfCoverage[];

// tax advantaged plans
export const TAX_ADVANTAGED_LOCS = [
  "hsa",
  "healthcare_fsa",
  "limited_purpose_fsa",
  "dependent_care_fsa",
] as LineOfCoverage[];

// Lines of coverage that allow the employee to elect certain coverage volumes within a range
export const CUSTOM_VOLUME_LOCS = [
  "voluntary_life",
  "voluntary_critical_illness",
  "voluntary_short_term_disability",
] as const satisfies readonly LineOfCoverage[];

export const ENROLLMENT_REASONS = {
  new_child: "Birth or Adoption",
  dependent_court_order: "Court Order",
  divorce: "Divorce or Legal Separation",
  domestic_partnership: "Domestic Partnership",
  marriage: "Marriage",
  relocated: "Relocated",
  dependent_lost_coverage: "Dependent Lost Coverage",
  lost_coverage: "Loss of Coverage",
  death: "Death of Dependent",
  open_enrollment: "Open Enrollment",
  leave_of_absence: "Leave of Absence",
  employment_change: "Employment Status Change",
  dependent_employment_change: "Dependent Employment Change",
  new_eligibility: "Newly Eligible",
  gain_coverage: "Gained Other Coverage",
  significant_plan_change: "Significant Plan Changes",
  dependent_aged_out: "Dependent Aged Out",
  dependent_gained_coverage: "Dependent Gained Coverage",
  citizenship_change: "Citizenship Status Change",
  release_from_incarceration: "Release from Incarceration",
};

export const DOCUMENT_TYPES = {
  summary_of_benefits_and_coverage: "Summary of Benefits and Coverage",
  "1095_c": "1095-C",
};

export const PLAN_TYPE = {
  ppo: {
    label: "PPO",
    tooltip:
      "Enables you to see any provider in-network, including specialists, without a referral. Can see out-of-network providers, but at a higher cost than in-network providers.",
    badgeColor: "#00527C",
    badgeBackground: "#E0F0FF",
  },
  hmo: {
    label: "HMO",
    tooltip:
      "Limits coverage to a network of providers in the associated geographic area. Requires you to see an in-network primary care physician first before seeing a specialist.",
    badgeColor: "#5E4200",
    badgeBackground: "#FFD6A4",
  },
  dppo: {
    label: "PPO",
    tooltip:
      "Provides flexibility with more provider choices, includes out-of-network coverage at higher costs.",
    badgeColor: "#00527C",
    badgeBackground: "#E0F0FF",
  },
  dhmo: {
    label: "HMO",
    tooltip:
      "Limits coverage to a network of providers in the associated geographic area.",
    badgeColor: "#5E4200",
    badgeBackground: "#FFD6A4",
  },
  vsp: {
    label: "VSP",
  },
  life: {
    label: "Life",
  },
  accidental_death: {
    label: "AD&D",
  },
  pos: {
    label: "POS",
  },
};

export enum BiologicalSex {
  Male = "male",
  Female = "female",
}
export const BiologicalSexOptions = [
  { value: BiologicalSex.Male, label: "Male" },
  { value: BiologicalSex.Female, label: "Female" },
];
export const PayFrequencyOptions = [
  { value: "biweekly", label: "Bi-weekly" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "semimonthly", label: "Semi-monthly" },
];
export const EmployeeTypeOptions = [
  { value: "hourly", label: "Hourly" },
  { value: "salaried", label: "Salaried" },
];

export const MemberRelationshipOptions: {
  [Relationship in MemberRelationship]: string;
} = {
  [MemberRelationship.Spouse]: "Spouse",
  [MemberRelationship.DomesticPartner]: "Domestic Partner",
  [MemberRelationship.CivilUnion]: "Civil Union",
  [MemberRelationship.ExSpouse]: "Ex Spouse",
  [MemberRelationship.Child]: "Child",
  [MemberRelationship.Grandchild]: "Grandchild",
  [MemberRelationship.Stepchild]: "Step Child",
  [MemberRelationship.Fosterchild]: "Foster Child",
  [MemberRelationship.Adoptedchild]: "Adopted Child",
  [MemberRelationship.LegalGuardianship]: "Legal Guardianship",
  [MemberRelationship.CourtOrderedDependent]: "Court Ordered Dependent",
  [MemberRelationship.Other]: "Other",
};

export const PartnerRelationshipTypes = [
  MemberRelationship.Spouse,
  MemberRelationship.DomesticPartner,
  MemberRelationship.CivilUnion,
  MemberRelationship.ExSpouse,
];

export const SUPPLEMENTAL_LOC_NAMES = {
  life: "Life",
  accidental_death: "AD&D",
  voluntary_life: "Voluntary Life",
  accident: "Accident",
  short_term_disability: "Short Term Disability",
  voluntary_short_term_disability: "Voluntary Short Term Disability",
  long_term_disability: "Long Term Disability",
  voluntary_critical_illness: "Voluntary Critical Illness",
  hospital_indemnity: "Hospital Indemnity",
};

export const TAX_ADVANTAGED_LOC_NAMES = {
  hsa: "Health Savings Account",
  healthcare_fsa: "Healthcare FSA",
  dependent_care_fsa: "Dependent Care FSA",
  limited_purpose_fsa: "Limited Purpose FSA",
};

export const PARTNER_LOGO_URLS = {
  Allstate: "https://clasp-public-assets.s3.amazonaws.com/logos/allstate.svg",
  Bennie: "https://clasp-public-assets.s3.amazonaws.com/logos/bennie.png",
};

export const TODAY = new Date(new Date().setHours(0, 0, 0, 0));

export const TAX_ADVANTAGED_PLAN_DETAILS = {
  hsa: [
    {
      header: "What is an HSA?",
      content:
        "A Health Savings Account is a tax-advantaged savings account for individuals with high-deductible medical plans that allows you to set aside pre-tax dollars to cover qualified medical expenses.",
    },
    {
      header: "Eligibility",
      content:
        "To open an HSA, you must be enrolled in a qualified High Deductible Health Plan (HDHP). If you’re enrolled in Medicare, TRICARE, or have used VA benefits in the past 3 months, or if you’re claimed as a dependent on someone else’s tax return, you are not eligible.",
    },
    {
      header: "Contribution Limits",
      content:
        "Your total annual contributions (employer + employee) must stay within IRS limits to avoid penalties. Be mindful of any personal contributions made outside payroll, as these won’t be tracked for you.",
    },
    {
      header: "Medicare Impact",
      content:
        "Contributions stop once you enroll in Medicare. If you’re approaching this milestone, please reach out to HR for next steps.",
    },
  ],
  healthcare_fsa: [
    {
      header: "What is a Healthcare FSA?",
      content:
        "A Healthcare FSA is a tax-advantaged account that allows you to set aside pre-tax dollars for a wide range of medical expenses, with funds that typically must be used within the plan year",
    },
    {
      header: "HSA Compatibility (Individual and  Family)",
      content:
        "You cannot enroll in a Healthcare FSA if you or your spouse currently contribute to a Health Savings Account (HSA).",
    },
  ],
  limited_purpose_fsa: [
    {
      header: "What is a Limited Purpose FSA?",
      content:
        "A Limited Purpose FSA account is a specialized FSA that works alongside an HSA and allows you to set aside pre-tax dollars to pay for specific qualified expenses, primarily dental and vision care.",
    },
    {
      header: "Eligibility",
      content:
        "Designed for employees with a High Deductible Health Plan (HDHP) and an active Health Savings Account (HSA). This account focuses on dental and vision expenses to complement your HSA.",
    },
  ],
  dependent_care_fsa: [
    {
      header: "What is a Dependent Care FSA?",
      content:
        "A Dependent Care FSA is a tax-advantaged savings account used to pay for eligible child care or adult dependent care expenses with pre-tax dollars.",
    },
    {
      header: "Tax Status Requirement",
      content:
        "This account is not available for individuals filing taxes as “married filing separately.”",
    },
    {
      header: "Contribution Limits for Couples",
      content:
        "If you’re married, the contribution limit is capped at the earned income of the lower-earning spouse. Monitor your contributions carefully to ensure compliance.",
    },
  ],
};

export const NUMBER_OF_PAY_PERIODS_YEARLY_BY_FREQUENCY = {
  weekly: 52,
  biweekly: 26,
  semimonthly: 24,
  monthly: 12,
};

export const PayPeriodOptions = [
  { value: "hourly", label: "Hourly" },
  { value: "annually", label: "Annually" },
];

export const PayPeriodMapping: Record<PayRatePeriod, string> = {
  hourly: "per hour",
  annually: "per year",
};

export const COVERAGE_TYPE_LABELS: Record<CoverageType, string> = {
  [CoverageType.Member]: "Employee",
  [CoverageType.MemberSpouse]: "Employee + Spouse",
  [CoverageType.MemberChild]: "Employee + Child",
  [CoverageType.MemberChildren]: "Employee + Children",
  [CoverageType.MemberFamily]: "Employee + Family",
};

export const AHS_SANBOX = "par_33imF3mzNr6zHSSjigCJC";
export const AHS_PROD = "par_gZWfoyivOX6e2j7w1F7cM";
