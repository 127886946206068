import { CarrierImage } from "@/components/CarrierImage";
import {
  DEFAULT_TABLE_PROPS,
  SUPPLEMENTAL_LOCS,
  TAX_ADVANTAGED_LOCS,
} from "@/constants";
import { isEmbedded } from "@/helpers";
import { InfoIcon } from "@/icons/Info";
import {
  clientAtom,
  configAtom,
  partnerConfigAtom,
  selectedPlanAtom,
  useNavigate,
} from "@/stores";
import {
  Alert,
  Box,
  Button,
  Center,
  Group,
  SegmentedControl,
  Stack,
  Title,
  Tooltip,
} from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import { useAtomValue, useSetAtom } from "jotai";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useEffect, useMemo, useState } from "react";
import { activePlansAtom, futurePlansAtom } from "../Review/API";

const PlanTableDefaults = {
  ...DEFAULT_TABLE_PROPS,
  enablePagination: false,
};

export const AdminPage = () => {
  const config = useAtomValue(configAtom);
  const { name: platformName } = useAtomValue(partnerConfigAtom[0]);
  const navigate = useNavigate();
  const setSelectedPlan = useSetAtom(selectedPlanAtom);
  const activePlans = useAtomValue(activePlansAtom);
  const futurePlans = useAtomValue(futurePlansAtom);
  const [plans, setPlans] = useState({
    medical: [],
    dental: [],
    vision: [],
    supplemental: [],
    taxAdvantaged: [],
  });

  const [benefitType, setBenefitType] = useState("current_benefits");

  const categorizePlans = (plans) => {
    return plans.reduce(
      (acc, plan) => {
        if (plan.line_of_coverage === "medical") {
          acc.medical.push(plan);
        } else if (plan.line_of_coverage === "dental") {
          acc.dental.push(plan);
        } else if (plan.line_of_coverage === "vision") {
          acc.vision.push(plan);
        } else if (SUPPLEMENTAL_LOCS.includes(plan.line_of_coverage)) {
          acc.supplemental.push(plan);
        } else if (TAX_ADVANTAGED_LOCS.includes(plan.line_of_coverage)) {
          acc.taxAdvantaged.push(plan);
        }
        return acc;
      },
      {
        medical: [],
        dental: [],
        vision: [],
        supplemental: [],
        taxAdvantaged: [],
      },
    );
  };

  const activePlansMap = useMemo(() => {
    return categorizePlans(activePlans);
  }, [activePlans]);
  const futurePlansMap = useMemo(() => {
    return categorizePlans(futurePlans);
  }, [futurePlans]);

  useEffect(() => {
    if (benefitType == "current_benefits") {
      setPlans(activePlansMap);
    } else {
      setPlans(futurePlansMap);
    }
  }, [benefitType, activePlansMap, futurePlansMap]);

  const client = useAtomValue(clientAtom);
  const generate1095CPreviews = async () => {
    const fileData = await client.generate1095CPreviews();
  };
  const enable1095CPreview = plans["medical"]?.every(
    (plan) =>
      plan.is_low_cost !== null &&
      plan.provides_essential_coverage !== null &&
      plan.provides_minimum_value !== null,
  );
  const columns = useMemo(() => {
    return [
      {
        header: "",
        accessorKey: "group.carrier.id",
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <Center>
            <CarrierImage carrierId={renderedCellValue} />
          </Center>
        ),
      },
      {
        header: "carrier name",
        accessorKey: "group.carrier.trade_name",
        size: 120,
      },
      {
        header: "plan name",
        accessorKey: "plan_name",
        size: 140,
      },
      {
        header: "group number",
        accessorKey: "group.group_number",
        size: 80,
      },
      {
        header: "enrollees",
        accessorKey: "enrollees",
        size: 130,
        Cell: ({ row }) => (
          <>
            {row.original.member_count} employees
            <br />
            {row.original.dependent_count} dependents
          </>
        ),
      },
      {
        header: "",
        accessorKey: "id",
        size: 80,
        Cell: ({ row }) => {
          const onClick = () => {
            setSelectedPlan(row.original.id);
            navigate("admin_plan_details");
          };
          return (
            <>
              <Button size="sm" onClick={onClick}>
                See details
              </Button>
            </>
          );
        },
      },
    ];
  }, [navigate, setSelectedPlan]);

  const medicalTable = useMantineReactTable({
    columns,
    data: plans["medical"],
    ...PlanTableDefaults,
  });

  const visionTable = useMantineReactTable({
    columns,
    data: plans["vision"],
    ...PlanTableDefaults,
  });

  const dentalTable = useMantineReactTable({
    columns,
    data: plans["dental"],
    ...PlanTableDefaults,
  });

  const supplementalTable = useMantineReactTable({
    columns,
    data: plans["supplemental"],
    ...PlanTableDefaults,
  });

  const taxAdvantagedTable = useMantineReactTable({
    columns,
    data: plans["taxAdvantaged"],
    ...PlanTableDefaults,
  });

  return (
    <Stack gap="lg">
      {!isEmbedded() && <Title order={2}>Your Benefits Summary</Title>}
      <Alert
        icon={<InfoIcon />}
        title={`${platformName} does not handle premium payments for you. Please
          ensure to pay your invoices on time, making payments directly to the
          insurance carrier.`}
      />
      <SegmentedControl
        mb="30px"
        style={{ padding: "2px 10px 2px 2px" }}
        value={benefitType}
        onChange={setBenefitType}
        data={[
          { label: "Current Benefits", value: "current_benefits" },
          { label: "Upcoming", value: "future_benefits" },
        ]}
      />
      <Group>
        <Title order={2}>Medical</Title>
        {client.config.apiClient !== "production" && (
          <Tooltip
            label="Every medical plan must have ACA details populated
          before generating the 1095-C previews"
            disabled={enable1095CPreview}
          >
            <Button
              onClick={generate1095CPreviews}
              variant="outline"
              disabled={!enable1095CPreview}
            >
              <IconDownload />
              Generate 1095-C Previews
            </Button>
          </Tooltip>
        )}
      </Group>
      <Box>
        <MantineReactTable table={medicalTable} />
      </Box>
      <Title order={2}>Dental</Title>
      <MantineReactTable table={dentalTable} />
      <Title order={2}>Vision</Title>
      <MantineReactTable table={visionTable} />
      <Title order={2}>Supplemental</Title>
      <MantineReactTable table={supplementalTable} />
      <Title order={2}>Tax Advantaged Accounts</Title>
      <MantineReactTable table={taxAdvantagedTable} />
    </Stack>
  );
};
