import { PayPeriodMapping } from "@/constants";
import { useApiClient } from "@/helpers/hooks";
import {
  snakeCaseToTitleCase,
  UsCurrencyFormatter,
  UsMediumDateFormatter,
} from "@/helpers/string";
import { Enrollment, EnrollmentPlan, Plan, RoleMapping } from "@/types/api";
import {
  Anchor,
  Box,
  Breadcrumbs,
  Button,
  Group,
  Stack,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronRight } from "@tabler/icons-react";
import { useNavigate, useParams } from "react-router-dom";
import {
  EditEmployeeAdditionalInformationModal,
  EditEmployeeDetailsModal,
} from "../EmployeeModal";
import SSNField from "../SSNField";
import { Section } from "../Section";
import { useGetPartnerConfig } from "../ViewEmployees/hooks";
import { CoverageTable } from "./CoverageTable";
import { PayRatesDrawer } from "./PayRatesDrawer";
import { useGetMember, usePayRates } from "./hooks";
import { getPayRateTypes, PayRateType } from "./utils";

export const SUPPLEMENTAL_LOCS = ["life", "accidental_death"];

export const ViewEmployeeDetail = () => {
  const { employeeId } = useParams();
  const { data: memberData, isLoading: isLoadingMember } = useGetMember(
    employeeId!,
  );
  const client = useApiClient();
  const partnerConfig = useGetPartnerConfig({ client });
  const { data: payRatesData, isLoading: isLoadingPayRates } =
    usePayRates(employeeId);
  const navigate = useNavigate();
  const [detailsOpened, { open: openDetails, close: closeDetails }] =
    useDisclosure(false);
  const [
    additionalInformationOpened,
    { open: openAdditionalInformation, close: closeAdditionalInformation },
  ] = useDisclosure(false);
  const [payRatesOpened, { open: openPayRates, close: closePayRates }] =
    useDisclosure(false);

  const enrollment = memberData?.active_enrollment as Enrollment | undefined;
  const enrollmentPlans = (enrollment?.enrolled_plans ||
    []) as EnrollmentPlan[];

  const medicalPlans = enrollmentPlans.filter(
    (enrolledPlan) =>
      (enrolledPlan.plan as Plan).line_of_coverage === "medical",
  );
  const visionPlans = enrollmentPlans.filter(
    (enrolledPlan) => (enrolledPlan.plan as Plan).line_of_coverage === "vision",
  );
  const dentalPlans = enrollmentPlans.filter(
    (enrolledPlan) => (enrolledPlan.plan as Plan).line_of_coverage === "dental",
  );
  const supplementalPlans = enrollmentPlans.filter((enrolledPlan) =>
    SUPPLEMENTAL_LOCS.includes((enrolledPlan.plan as Plan).line_of_coverage),
  );

  const currentPayRate = getPayRateTypes(payRatesData?.results ?? []).find(
    ({ type }) => type === PayRateType.Current,
  );

  const handleReturnToList = () => {
    navigate("/employer/view-employees");
  };

  return (
    <Stack gap="xl">
      <Breadcrumbs separator={<IconChevronRight size={17} />}>
        <Anchor onClick={handleReturnToList}>Employees</Anchor>
        <Anchor c="gray.8" underline="never">
          {memberData?.first_name} {memberData?.last_name}
        </Anchor>
      </Breadcrumbs>
      <Section
        title="Details"
        withEditButton={partnerConfig?.data?.flags?.enable_employee_management}
        onClickEdit={openDetails}
      >
        <Section.Field title="First Name" isLoading={isLoadingMember}>
          {memberData?.first_name}
        </Section.Field>
        <Section.Field title="Last Name" isLoading={isLoadingMember}>
          {memberData?.last_name}
        </Section.Field>

        <Section.Field title="Phone Number" isLoading={isLoadingMember}>
          {memberData?.phone_number ?? "-"}
        </Section.Field>
        <Section.Field title="Email" isLoading={isLoadingMember}>
          {memberData?.email ?? "-"}
        </Section.Field>
        <Section.Field title="Role" isLoading={isLoadingMember}>
          {memberData?.role ? RoleMapping[memberData.role] : "-"}
        </Section.Field>
        <SSNField
          isLoading={isLoadingMember}
          entityId={employeeId}
          entityType="member"
          ssnLastFour={memberData?.ssn_last_four}
        />
        <Section.Field title="Date of Birth" isLoading={isLoadingMember}>
          {memberData?.dob
            ? UsMediumDateFormatter.format(new Date(memberData.dob))
            : "-"}
        </Section.Field>
        <Section.Field title="Hire Date" isLoading={isLoadingMember}>
          {memberData?.hire_date
            ? UsMediumDateFormatter.format(new Date(memberData.hire_date))
            : "-"}
        </Section.Field>
        <Section.Field title="Termination Date" isLoading={isLoadingMember}>
          {memberData?.termination_date
            ? UsMediumDateFormatter.format(
                new Date(memberData.termination_date),
              )
            : "-"}
        </Section.Field>
        <Section.Field title="Biological Sex" isLoading={isLoadingMember}>
          {snakeCaseToTitleCase(memberData?.biological_sex) ?? "-"}
        </Section.Field>
        <Section.Field title="Tobacco Usage" isLoading={isLoadingMember}>
          {memberData?.tobacco_usage === null
            ? "-"
            : memberData?.tobacco_usage
            ? "Yes"
            : "No"}
        </Section.Field>
      </Section>

      <Section
        title="Additional Information"
        withEditButton={partnerConfig?.data?.flags?.enable_employee_management}
        onClickEdit={openAdditionalInformation}
      >
        <Section.Field title="Home Address" isLoading={isLoadingMember}>
          {memberData?.address?.line1 ?? "-"} <br />
          {memberData?.address?.line2 ? (
            <>
              {memberData?.address?.line2}
              <br />
            </>
          ) : (
            ""
          )}
          {memberData?.address?.city}, {memberData?.address?.state}
          <br />
          {memberData?.address?.zip_code ?? "-"}
        </Section.Field>
        <Section.Field title="Hours Worked" isLoading={isLoadingMember}>
          {memberData?.hours_worked} / Week
        </Section.Field>
        <Section.Field title="Pay Frequency" isLoading={isLoadingMember}>
          {snakeCaseToTitleCase(memberData?.pay_frequency)}
        </Section.Field>
        <Section.Field
          title={
            <Group gap={4}>
              Pay
              {(payRatesData?.results ?? []).length > 0 && (
                <Box>
                  (
                  <Button
                    p={0}
                    size="compact-sm"
                    variant="subtle"
                    onClick={openPayRates}
                    vars={() => ({
                      root: {
                        "--button-hover": "transparent",
                      },
                    })}
                  >
                    View Details
                  </Button>
                  )
                </Box>
              )}
            </Group>
          }
          isLoading={isLoadingPayRates}
        >
          {currentPayRate ? (
            <>
              {UsCurrencyFormatter.format(currentPayRate.amount)}{" "}
              {PayPeriodMapping[currentPayRate.period]}
            </>
          ) : (
            "-"
          )}
        </Section.Field>
      </Section>
      {enrollment && memberData && (
        <div>
          <Stack gap="lg">
            <Text size="lg">Current Enrollment</Text>

            {medicalPlans.length > 0 && (
              <Text size="md">
                Medical
                <CoverageTable
                  enrollmentPlans={medicalPlans as EnrollmentPlan[]}
                  enrollment={enrollment}
                  member={memberData}
                />
              </Text>
            )}
            {visionPlans.length > 0 && (
              <Text size="md">
                Vision
                <CoverageTable
                  enrollmentPlans={visionPlans as EnrollmentPlan[]}
                  enrollment={enrollment}
                  member={memberData}
                />
              </Text>
            )}
            {dentalPlans.length > 0 && (
              <Text size="md">
                Dental
                <CoverageTable
                  enrollmentPlans={dentalPlans as EnrollmentPlan[]}
                  enrollment={enrollment}
                  member={memberData}
                />
              </Text>
            )}
            {supplementalPlans.length > 0 && (
              <Text size="md">
                Supplemental Benefits
                <CoverageTable
                  enrollmentPlans={supplementalPlans as EnrollmentPlan[]}
                  enrollment={enrollment}
                  member={memberData}
                />
              </Text>
            )}
          </Stack>{" "}
        </div>
      )}
      <EditEmployeeDetailsModal
        employeeId={employeeId}
        opened={detailsOpened}
        onClose={closeDetails}
      />
      <EditEmployeeAdditionalInformationModal
        employeeId={employeeId}
        opened={additionalInformationOpened}
        onClose={closeAdditionalInformation}
      />
      <PayRatesDrawer
        member={employeeId}
        onClose={closePayRates}
        opened={payRatesOpened}
      />
    </Stack>
  );
};
