import { Address } from "@/types/api";
import { Text } from "@mantine/core";

interface Props {
  dob: string;
  biological_sex: string;
  address: Address;
  ssn: string;
  memberRelationship?: string;
}

export const PersonInfo = ({
  dob,
  biological_sex,
  address,
  ssn,
  memberRelationship,
}: Props) => {
  const { city, line1, line2, state, zip_code } = address;

  return (
    <>
      <Text fw={400} inline>
        Birthday: <b>{dob}</b>
      </Text>
      {ssn && (
        <Text fw={400} inline>
          SSN: <b>{ssn}</b>
        </Text>
      )}
      {biological_sex && (
        <Text fw={400}>
          Biological Sex: <b>{biological_sex}</b>
        </Text>
      )}
      {memberRelationship && (
        <Text fw={400}>
          Relationship: <b>{memberRelationship}</b>
        </Text>
      )}
      <Text fw={400}>
        Address:
        <Text fw={600}>
          {line1}
          {line2 && (
            <>
              <br />
              {line2}
            </>
          )}
          ,<br />
          {city}, {state} {zip_code}
        </Text>
      </Text>
    </>
  );
};
