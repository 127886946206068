import { Avatar, Group, Text, useMantineTheme } from "@mantine/core";
import { useStytchUser } from "@stytch/react";
import { useAtomValue } from "jotai";
import { entityIdAtom } from "@/stores";
import { useState, useEffect } from "react";
import axios from "axios";
import { getClaspApiUrl } from "@/helpers";
import { authAtom } from "@/App";

export const UserProfile = () => {
  const { user } = useStytchUser();
  const theme = useMantineTheme();
  const auth = useAtomValue(authAtom);
  const environment = localStorage.getItem("environment");
  const baseUrl = getClaspApiUrl(environment as any);
  const [memberInfo, setMemberInfo] = useState(null);

  const memberId = localStorage.getItem("member_id");

  useEffect(() => {
    if (memberId) {
      const fetchMemberInfo = async () => {
        try {
          const response = await axios.get(`${baseUrl}/members/${memberId}`, {
            withCredentials: true,
            headers: auth["access_token"] && {
              Authorization: `Bearer ${auth["access_token"]}`,
            },
          });
          setMemberInfo(response.data);
        } catch (error) {
          console.error("Error fetching member info:", error);
        }
      };

      fetchMemberInfo();
    }
  }, [memberId, baseUrl, auth]);

  const firstName = memberInfo?.first_name || user?.name.first_name || "";
  const lastName = memberInfo?.last_name || user?.name.last_name || "";

  return firstName || lastName ? (
    <Group align="center" justify="space-between">
      <Text fw={500} c={"gray.8"}>
        {firstName} {lastName}
      </Text>
      <Avatar size={32} radius="xl" color={theme.colors[theme.primaryColor][5]}>
        {firstName[0]?.toUpperCase() || ""}
        {lastName[0]?.toUpperCase() || ""}
      </Avatar>
    </Group>
  ) : (
    <></>
  );
};
