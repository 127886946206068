import { downloadFile } from "@/helpers";
import { useApiClient } from "@/helpers/hooks";
import { entityIdAtom } from "@/stores";
import { EmployerReport } from "@/types/api";
import { Button, Card, Grid, Stack, Text, Title, Tooltip } from "@mantine/core";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { PartnerConfig } from "@/types/api";

export const ReportDownloadCard = ({
  reportType,
  reportName,
  reportDescription,
}: {
  reportType: EmployerReport;
  reportName: string;
  reportDescription: string;
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const apiClient = useApiClient();
  const employerId = useAtomValue(entityIdAtom);
  const partnerConfig = useOutletContext() as PartnerConfig;

  const onDownload = async () => {
    try {
      setIsDownloading(true);
      const response = await apiClient.fetchReport(employerId, reportType);
      downloadFile(response);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Grid.Col span={{ xl: 3, lg: 4, md: 4, sm: 6, xs: 6 }}>
      <Card
        shadow="xs"
        h="100%"
        withBorder
        style={{ borderColor: "var(--mantine-color-gray-1" }}
      >
        <Stack justify="space-between" h="100%">
          <Stack>
            <Title order={4}>{reportName}</Title>
            <Text size="xs" c="gray.7">
              {reportDescription}
            </Text>
          </Stack>
          <Button
            onClick={() => onDownload()}
            loading={isDownloading}
            color={partnerConfig.theme.primaryColor}
          >
            Download
          </Button>
        </Stack>
      </Card>
    </Grid.Col>
  );
};
