import { AddressFieldGroup } from "@/components/Form/AddressFieldGroup";
import { OptionalRadioGroup } from "@/components/Form/OptionalRadioGroup";
import { MemberRelationshipOptions } from "@/constants";
import { configAtom } from "@/stores";
import { ClaspEmployeeConfig } from "@/types";
import { Dependent, Employer } from "@/types/api";
import {
  Button,
  Grid,
  Group,
  Modal,
  Radio,
  RadioGroup,
  ScrollArea,
  Select,
  Stack,
  TextInput,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import dayjs from "dayjs";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";
import {
  createDependentAtom,
  memberDependentsInfiniteQueryAtom,
  memberInfoAtom,
  updateDependentAtom,
} from "../API";
import { EditDependent, EditDependentSchema } from "./form";

interface Props {
  opened: boolean;
  close: () => void;
  dependent?: Dependent;
}

const generateFormInitialValues = (
  dependent: Dependent,
  isEditing: boolean,
) => {
  return isEditing
    ? {
        firstName: dependent.first_name,
        lastName: dependent.last_name,
        ssn: `***-**-${dependent.ssn_last_four}`,
        dob: dayjs(dependent.dob).toDate(),
        biologicalSex: dependent.biological_sex,
        address: {
          line1: dependent.address.line1,
          line2: dependent.address.line2,
          city: dependent.address.city,
          state: dependent.address.state,
          zip_code: dependent.address.zip_code,
        },
        memberRelationship: dependent.member_relationship,
        tobaccoUsage: dependent.tobacco_usage,
      }
    : { address: {} };
};

export const DependentForm = ({ opened, close, dependent }: Props) => {
  const isEditing = !!dependent;
  const [createDependentStatus, createDependent] = useAtom(createDependentAtom);
  const [updateDependentStatus, updateDependent] = useAtom(updateDependentAtom);
  const memberDependentsInfiniteQuery = useAtom(
    memberDependentsInfiniteQueryAtom[1],
  );
  const memberId = (useAtomValue(configAtom) as unknown as ClaspEmployeeConfig)
    .employeeId;
  const member = useAtomValue(memberInfoAtom[0]);
  const hasTobaccoRatePlan = (member.employer as Employer)
    .has_tobacco_rated_plan;
  const initialValues = generateFormInitialValues(dependent, isEditing);
  const theme = useMantineTheme();
  const mediaQuery = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);

  const form = useForm<EditDependent>({
    initialValues: initialValues,
    validate: zodResolver(EditDependentSchema),
  });

  const { setValues } = form;

  useEffect(() => {
    const newInitialValues = generateFormInitialValues(dependent, isEditing);
    setValues(newInitialValues);
  }, [dependent, setValues, isEditing]);

  const onSubmit = async (values: EditDependent) => {
    const { ssn, dob, tobaccoUsage, ...parsedValues } =
      EditDependentSchema.parse(values);
    if (hasTobaccoRatePlan && tobaccoUsage == null) {
      form.setFieldError("tobaccoUsage", "Required field");
      return;
    }

    const requestBody = {
      ...parsedValues,
      dob: dob.toISOString().substring(0, 10),
      member: memberId,
      tobacco_usage: tobaccoUsage,
      ...(ssn.indexOf("*") >= 0 ? {} : { ssn }),
    };
    isEditing
      ? await updateDependent([
          { dependentId: dependent.id, body: requestBody },
        ])
      : await createDependent([requestBody]);
    await memberDependentsInfiniteQuery[0].refetch();
    form.reset();
    close();
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      title={
        <Title size="h2">{`${isEditing ? "Edit" : "Add"} Dependent`}</Title>
      }
      size={mediaQuery ? "auto" : "lg"}
      padding="xl"
    >
      <form onSubmit={form.onSubmit(onSubmit)}>
        <ScrollArea pr={16}>
          <Stack style={{ flex: 1, overflowY: "auto" }}>
            <Grid>
              <Grid.Col span={{ base: 12, sm: 6 }}>
                <TextInput
                  label="First name"
                  {...form.getInputProps("firstName")}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, sm: 6 }}>
                <TextInput
                  label="Last name"
                  {...form.getInputProps("lastName")}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, sm: 6 }}>
                <TextInput label="SSN" {...form.getInputProps("ssn")} />
              </Grid.Col>
              <Grid.Col span={{ base: 12, sm: 6 }}>
                <DateInput label="Birthday" {...form.getInputProps("dob")} />
              </Grid.Col>
              <Grid.Col span={{ base: 12, sm: 6 }}>
                <Select
                  label="Relationship"
                  data={Object.entries(MemberRelationshipOptions).map(
                    ([key, value]) => ({ value: key, label: value }),
                  )}
                  placeholder="Select Relationship"
                  {...form.getInputProps("memberRelationship")}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, sm: 4 }}>
                <RadioGroup
                  label="Biological Sex"
                  {...form.getInputProps("biologicalSex")}
                  mb={15}
                >
                  <Group>
                    <Radio value="male" label="Male" />
                    <Radio value="female" label="Female" />
                  </Group>
                </RadioGroup>
                {hasTobaccoRatePlan && (
                  <OptionalRadioGroup
                    label="Tobacco Usage"
                    {...form.getInputProps("tobaccoUsage")}
                  >
                    <Group>
                      <Radio value="true" label="Yes" />
                      <Radio value="false" label="No" />
                    </Group>
                  </OptionalRadioGroup>
                )}
              </Grid.Col>
            </Grid>
            <div>
              <Title size="h3">Address</Title>
              <AddressFieldGroup form={form} basePath="address" />
            </div>
          </Stack>
        </ScrollArea>
        <Group justify="flex-end" mt={8} pt={8}>
          <Button variant="outline" onClick={close}>
            Cancel
          </Button>
          <Button
            type="submit"
            loading={
              createDependentStatus.isPending ||
              updateDependentStatus.isPending ||
              memberDependentsInfiniteQuery[0].isFetching
            }
          >
            Save
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
