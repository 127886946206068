import { PartnerRelationshipTypes } from "@/constants";
import { AddressSchema, SsnSchema } from "@/schemas";
import { MemberRelationship } from "@/types/api";
import { z } from "zod";
import { add } from "date-fns";
import { isYoungerThan } from "@/helpers/date";

export const EditDependentSchema = z
  .object({
    firstName: z.string(),
    lastName: z.string(),
    dob: z
      .date()
      .max(
        new Date(),
        "Dependent's date of birth is in the future. Please enter a valid date of birth.",
      ),
    ssn: SsnSchema,
    biologicalSex: z.string(),
    address: AddressSchema,
    memberRelationship: z.nativeEnum(MemberRelationship),
    tobaccoUsage: z.boolean().nullable().optional(),
  })
  .refine(
    (values) => {
      return isYoungerThan(values.dob, 100);
    },
    {
      message:
        "Dependent's age is 100 or greater. Please verify the date of birth.",
      path: ["dob"],
    },
  )
  .refine(
    (values) => {
      const isChild = !PartnerRelationshipTypes.includes(
        values.memberRelationship,
      );
      const isYoungerThan30 = isYoungerThan(values.dob, 30);
      return isChild ? isYoungerThan30 : true;
    },
    {
      message:
        "Child depedent has age over 30. Please verify the date of birth.",
      path: ["dob"],
    },
  );

export type EditDependent = z.infer<typeof EditDependentSchema>;
