import { Button, Tooltip } from "@mantine/core";

interface Props {
  selected?: boolean;
  onClick?: () => void;
  selectedText?: string;
  unselectedText?: string;
  canBeSelected?: boolean;
  loading?: boolean;
}
export const SelectButton = ({
  selected = false,
  onClick = () => {},
  selectedText = "Selected",
  unselectedText = "Select Plan",
  canBeSelected = true,
  loading = false,
}: Props) => {
  if (!canBeSelected) {
    return (
      <Tooltip
        label="This option may not be selected due to company policy."
        position="top"
        withArrow
      >
        <Button variant="light" disabled loading={loading}>
          {unselectedText}
        </Button>
      </Tooltip>
    );
  }

  if (selected) {
    return (
      <Button onClick={onClick} loading={loading} variant="light">
        {selectedText}
      </Button>
    );
  }

  return (
    <Button onClick={onClick} loading={loading} variant="outline">
      {unselectedText}
    </Button>
  );
};
