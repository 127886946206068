import { selectedEnrollmentAtom, useNavigate } from "@/stores";
import {
  Button,
  ComboboxItem,
  ComboboxItemGroup,
  Divider,
  Flex,
  Group,
  List,
  OptionsData,
  Pill,
  Select,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { Dropzone, FileWithPath, MIME_TYPES } from "@mantine/dropzone";
import { notifications } from "@mantine/notifications";
import { IconUpload } from "@tabler/icons-react";
import { useAtom, useSetAtom } from "jotai";
import { useState } from "react";
import {
  postQualifyingLifeEventAtom,
  postQualifyingLifeEventDocumentAtom,
} from "./API";
import { QLE_REASONS } from "./constants";

const options: OptionsData = Object.entries(QLE_REASONS).reduce<
  ComboboxItemGroup<ComboboxItem>[]
>((groups, [key, reason]) => {
  const group = groups.find((g) => g.group === reason.group);
  const item = { value: key, label: reason.label };
  if (group) {
    group.items.push(item);
  } else {
    groups.push({ group: reason.group, items: [item] });
  }
  return groups;
}, []);

export const EmployeeQLE = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [reason, setReason] = useState<string>();
  const [date, setDate] = useState<Date | null>(null);
  const [, postQualifyingLifeEvent] = useAtom(postQualifyingLifeEventAtom);
  const [, postQualifyingLifeEventDocument] = useAtom(
    postQualifyingLifeEventDocumentAtom,
  );
  const setSelectedEnrollment = useSetAtom(selectedEnrollmentAtom);
  const [uploadedFiles, setUploadedFiles] = useState(new Array<FileWithPath>());

  const onChangeDate = (value) => {
    setDate(value);
  };

  const onSubmit = async () => {
    const enrollment = await postQualifyingLifeEvent([
      {
        reason: reason,
        effective_date: date.toISOString().substring(0, 10),
      },
    ]);
    for (const file of uploadedFiles) {
      const formData = new FormData();
      formData.set("file", file);
      formData.set("name", file.name);
      formData.set("enrollment", enrollment.id);
      formData.set("employer", enrollment.member.employer);
      await postQualifyingLifeEventDocument([formData]);
    }
    setSelectedEnrollment(enrollment);
    navigate("enroll");
  };

  const allFieldsPopulated = reason && date;

  return (
    <Flex direction="column" gap={20} maw={900}>
      <Stack gap="xs">
        <Title size="h2">Select Your Qualifying Life Event</Title>

        <Text>
          A Qualifying Life Event (QLE) lets you change your benefits outside
          the annual open enrollment period. Choose the event that matches your
          situation.
        </Text>
        <Select
          placeholder="Select a Qualifying Life Event"
          data={options}
          searchable
          variant="default"
          onChange={setReason}
          value={reason}
          styles={{
            groupLabel: {
              fontSize: theme.fontSizes.sm,
              fontWeight: 600,
            },
          }}
        />
      </Stack>

      <Divider />

      <Title size="h3">Selected Event: {QLE_REASONS[reason]?.label}</Title>
      <Text>
        {reason
          ? QLE_REASONS[reason].description
          : "Please select a qualifying life event from the dropdown above."}
      </Text>
      <Text fw={600}>Next Steps:</Text>
      <List type="ordered" pl="md">
        <List.Item>
          Select the date when this event occurred or will occur.
        </List.Item>
        <List.Item>
          Upload any relevant documentation to support your qualifying life
          event.
        </List.Item>
        <List.Item>
          Review and update your benefit plans to reflect your new situation.
        </List.Item>
        <List.Item>Submit your changes for approval.</List.Item>
      </List>
      <Stack gap="xs">
        <Title size="h4">Select the Date of Benefit Coverage Change</Title>
        <DatePickerInput
          placeholder="Select a date"
          value={date}
          variant="default"
          onChange={onChangeDate}
          disabled={!reason}
        />
      </Stack>
      <Stack gap="xs">
        <Title size="h4">Upload Relevant Documentation</Title>
        <Text size="sm" c="dimmed">
          Examples of valid documentation are marriage certificates, birth
          certificates, or your spouse's offer letter showing a change in
          employment. It's important to upload correct documents, with clear
          names and dates, to ensure that your enrollment is processed correctly
          and in a timely manner.
        </Text>
        <Group gap="sm">
          {uploadedFiles.map((file) => (
            <Pill
              size="lg"
              withRemoveButton
              onRemove={() =>
                setUploadedFiles(uploadedFiles.filter((f) => f !== file))
              }
            >
              {file.name}
            </Pill>
          ))}
        </Group>

        <Dropzone
          onDrop={(files) => setUploadedFiles([...uploadedFiles, ...files])}
          maxFiles={5}
          maxSize={5 * 1024 * 1024} // 5mb max file size
          accept={[MIME_TYPES.pdf]}
          onReject={() =>
            notifications.show({
              color: "red",
              message:
                "The uploaded file does not meet the requirements. \
              Please ensure only PDF files under 5mb are selected.",
              autoClose: false,
            })
          }
          multiple
        >
          <Stack align="center" justify="center" mih={100}>
            <IconUpload style={{ width: 30, height: 30 }} stroke={1} />
            <Text size="lg" inline>
              Drag files here or click to select files
            </Text>
            <Text size="xs" c="dimmed">
              Only .pdf files smaller than 5 MB are accepted
            </Text>
          </Stack>
        </Dropzone>
      </Stack>

      <Flex justify="flex-end" gap={12}>
        <Button variant="outline" onClick={() => navigate("employee_home")}>
          Cancel
        </Button>
        <Button onClick={onSubmit} disabled={!allFieldsPopulated}>
          Continue
        </Button>
      </Flex>
    </Flex>
  );
};
