import { getCursor } from "@/helpers";
import { fetchAllPages } from "@/helpers/hooks";
import { clientAtom, configAtom } from "@/stores";
import { ClaspEmployeeConfig } from "@/types";
import {
  BeneficiaryAllocation,
  EmployerDocument,
  Member,
  MemberDocument,
  Paginated,
} from "@/types/api";
import { mutationAtomType } from "@/types/jotai";
import { Atom, atom } from "jotai";
import {
  atomsWithInfiniteQuery,
  atomsWithMutation,
  atomsWithQuery,
} from "jotai-tanstack-query";

export const memberInfoAtom: ReturnType<typeof atomsWithQuery<Member>> =
  atomsWithQuery<Member>((get) => ({
    queryKey: [
      "members",
      get(configAtom as unknown as Atom<ClaspEmployeeConfig>).employeeId,
    ],
    queryFn: async ({ queryKey: [, id] }) => {
      const client = await get(clientAtom);
      return await client.getMemberInfo(id as string, {
        expand: [
          "active_enrollment.enrolled_plans.plan.group",
          "active_enrollment.enrolled_plans.enrolled_dependents.dependent",
          "enrollments.enrolled_plans.plan.group",
          "enrollments.enrolled_plans.enrolled_dependents.dependent",
          "employer",
        ],
      });
    },
  }));

export const [, postBeginOpenEnrollmentAtom] = atomsWithMutation((get) => ({
  mutationKey: ["postBeginOpenEnrollment"],
  mutationFn: async (body: any) => {
    const client = await get(clientAtom);
    return await client.postBeginOpenEnrollment(
      get(configAtom as unknown as Atom<ClaspEmployeeConfig>).employeeId,
      body,
    );
  },
})) as [any, mutationAtomType];

export const [, postNewHireEnrollmentAtom] = atomsWithMutation((get) => ({
  mutationKey: ["postBeginNewHireEnrollment"],
  mutationFn: async (body: any) => {
    const client = await get(clientAtom);
    return await client.postNewHireEnrollment(
      get(configAtom as unknown as Atom<ClaspEmployeeConfig>).employeeId,
      body,
    );
  },
})) as [any, mutationAtomType];

export const employerDocumentsInfiniteQueryAtom: ReturnType<
  typeof atomsWithInfiniteQuery<Paginated<EmployerDocument>>
> = atomsWithInfiniteQuery<Paginated<EmployerDocument>>((get) => ({
  queryKey: [
    "employerDocuments",
    get(configAtom as unknown as Atom<ClaspEmployeeConfig>).employeeId,
  ],
  queryFn: async ({ pageParam, queryKey }) => {
    const client = await get(clientAtom);
    return client.listEmployerDocuments({
      cursor: pageParam as string | undefined,
    });
  },
  getNextPageParam: (lastPage) => getCursor(lastPage.next),
  initialPageParam: undefined,
}));

export const employerDocumentsAtom = atom(async (get) => {
  return fetchAllPages(
    await get(employerDocumentsInfiniteQueryAtom[0]),
    get(employerDocumentsInfiniteQueryAtom[1]),
  );
});

export const memberDocumentsInfiniteQueryAtom: ReturnType<
  typeof atomsWithInfiniteQuery<Paginated<MemberDocument>>
> = atomsWithInfiniteQuery<Paginated<MemberDocument>>((get) => ({
  queryKey: [
    "memberDocuments",
    get(configAtom as unknown as Atom<ClaspEmployeeConfig>).employeeId,
  ],
  queryFn: async ({ pageParam, queryKey }) => {
    const client = await get(clientAtom);
    return client.listMemberDocuments({
      cursor: pageParam as string | undefined,
      member: get(configAtom as unknown as Atom<ClaspEmployeeConfig>)
        .employeeId,
    });
  },
  getNextPageParam: (lastPage) => getCursor(lastPage.next),
  initialPageParam: undefined,
}));

export const memberDocumentsAtom = atom(async (get) => {
  return fetchAllPages(
    await get(memberDocumentsInfiniteQueryAtom[0]),
    get(memberDocumentsInfiniteQueryAtom[1]),
  );
});

export const listBeneficiaryAllocationAtom: ReturnType<
  typeof atomsWithQuery<BeneficiaryAllocation[]>
> = atomsWithQuery((get) => ({
  queryKey: [
    "beneficiaryAllocation",
    get(configAtom as unknown as Atom<ClaspEmployeeConfig>).employeeId,
  ],
  queryFn: async ({ queryKey: [_, id] }) => {
    const client = await get(clientAtom);
    return (
      await client.listBeneficiaryAllocations({
        member: id as string,
        expand: ["splits.beneficiary"],
      })
    ).results;
  },
}));
