import { ENROLLMENT_REASONS } from "@/constants";

enum QLE_REASON_GROUP {
  FAMILY_CHANGES = "Family Changes",
  EMPLOYMENT_CHANGES = "Employment Changes",
  RESIDENCE_CHANGES = "Residence Changes",
  COVERAGE_CHANGES = "Coverage Changes",
  DEPENDENT_ELIGIBILITY_CHANGES = "Dependent Eligibility Changes",
  LEGAL_OR_CITIZENSHIP_CHANGES = "Legal or Citizenship Changes",
}

type QLEReason = {
  group: QLE_REASON_GROUP;
  label: string;
  description: string;
};

export const QLE_REASONS: Partial<
  Record<keyof typeof ENROLLMENT_REASONS, QLEReason>
> = {
  marriage: {
    group: QLE_REASON_GROUP.FAMILY_CHANGES,
    label: "Getting Married",
    description:
      "You recently got married and your benefits coverage needs to account for your spouse.",
  },
  domestic_partnership: {
    group: QLE_REASON_GROUP.FAMILY_CHANGES,
    label: "Entering a Domestic Partnership",
    description:
      "You recently entered a domestic partnership and your benefits coverage needs to account for your partner.",
  },
  divorce: {
    group: QLE_REASON_GROUP.FAMILY_CHANGES,
    label: "Getting Divorced or Legally Separated",
    description:
      "Your marriage has ended and you need to adjust your benefit coverage.",
  },
  new_child: {
    group: QLE_REASON_GROUP.FAMILY_CHANGES,
    label: "Having a Baby or Adopting a Child",
    description:
      "You've had a baby, adopted a child, or had a child placed with you for foster care.",
  },
  death: {
    group: QLE_REASON_GROUP.FAMILY_CHANGES,
    label: "Death of a Covered Family Member",
    description:
      "A dependent on your plan has passed away, affecting your benefits.",
  },
  employment_change: {
    group: QLE_REASON_GROUP.EMPLOYMENT_CHANGES,
    label: "Change in Your Employment Status",
    description:
      "Your work hours or employment status have changed, affecting your eligibility for benefits.",
  },
  dependent_employment_change: {
    group: QLE_REASON_GROUP.EMPLOYMENT_CHANGES,
    label: "Spouse or Dependent's Employment Status Changes",
    description:
      "Your spouse or dependent gains or loses a job, impacting their benefits and your coverage options.",
  },
  leave_of_absence: {
    group: QLE_REASON_GROUP.EMPLOYMENT_CHANGES,
    label: "Taking a Leave of Absence",
    description:
      "You're taking a leave of absence from your job, affecting your benefits coverage.",
  },
  relocated: {
    group: QLE_REASON_GROUP.RESIDENCE_CHANGES,
    label: "Moving to a New Residence",
    description:
      "You're relocating to a different zip code or county that affects your plan options or network availability.",
  },
  new_eligibility: {
    group: QLE_REASON_GROUP.COVERAGE_CHANGES,
    label: "Newly Eligible for Coverage",
    description:
      "You've newly become eligible for health coverage provided by your employer.",
  },
  lost_coverage: {
    group: QLE_REASON_GROUP.COVERAGE_CHANGES,
    label: "Losing Other Health Coverage",
    description:
      "You or a dependent have lost existing health insurance coverage.",
  },
  gain_coverage: {
    group: QLE_REASON_GROUP.COVERAGE_CHANGES,
    label: "Gaining Access to New Coverage",
    description:
      "You've become eligible for new health coverage options, such as Medicare, Medicaid, or a spouse's plan.",
  },
  significant_plan_change: {
    group: QLE_REASON_GROUP.COVERAGE_CHANGES,
    label: "Significant Change in Cost or Coverage of Your Plan",
    description:
      "There's a significant increase or decrease in the cost of your current plan, or substantial changes to the coverage it provides.",
  },
  dependent_aged_out: {
    group: QLE_REASON_GROUP.DEPENDENT_ELIGIBILITY_CHANGES,
    label: "Child Turning 26 Years Old",
    description:
      "Your dependent child has reached the age limit for coverage under your plan and may need their own coverage.",
  },
  dependent_gained_coverage: {
    group: QLE_REASON_GROUP.DEPENDENT_ELIGIBILITY_CHANGES,
    label: "Dependent Gains Other Coverage",
    description:
      "Your dependent became eligible for their own employer-sponsored health plan or a plan through a spouse.",
  },
  dependent_lost_coverage: {
    group: QLE_REASON_GROUP.DEPENDENT_ELIGIBILITY_CHANGES,
    label: "Dependent Loses Other Coverage",
    description: "Your dependent lost their employer-sponsored health plan.",
  },
  dependent_court_order: {
    group: QLE_REASON_GROUP.LEGAL_OR_CITIZENSHIP_CHANGES,
    label: "Qualified Medical Child Support Order",
    description:
      "A court order requires you to provide or remove health coverage for a child.",
  },
  citizenship_change: {
    group: QLE_REASON_GROUP.LEGAL_OR_CITIZENSHIP_CHANGES,
    label: "Becoming a U.S. Citizen or Lawful Permanent Resident",
    description:
      "You gain U.S. citizenship or lawful residency status, affecting your eligibility for benefits.",
  },
  release_from_incarceration: {
    group: QLE_REASON_GROUP.LEGAL_OR_CITIZENSHIP_CHANGES,
    label: "Release from Incarceration",
    description:
      "You've been released from jail or prison and need to enroll in health coverage.",
  },
};
