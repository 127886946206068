import { UsMediumDateFormatter } from "@/helpers/string";
import { partnerConfigAtom } from "@/stores";
import { Button, List, Stack, Text, Title } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import { useAtomValue } from "jotai";
import { getEnrollmentAtom } from "../API";
import { AHS_PROD, AHS_SANBOX } from "@/constants";

export const CompletedStep = ({ onNext }: { onNext: () => void }) => {
  const enrollment = useAtomValue(getEnrollmentAtom[0]);
  const { id: partnerId, name: platformName } = useAtomValue(
    partnerConfigAtom[0],
  );
  return partnerId === AHS_SANBOX || partnerId === AHS_PROD ? (
    <Stack gap={24} h="calc(100vh - 64px)" justify="center" align="center">
      <Title order={2}>Selections complete - here are next steps</Title>

      <List>
        <List.Item>
          We’ll coordinate with Allstate Health Solutions and the carriers to
          ensure all enrollment paperwork is processed so your benefits start on
          time.
        </List.Item>
        <List.Item>
          You’ll receive a confirmation email from AllstateHealth@WithClasp.com
          once your enrollment package has been sent to the carrier.
        </List.Item>
        <List.Item>
          Keep an eye on your inbox for any follow-up emails from us in case the
          carrier needs additional information.
        </List.Item>
      </List>
      <Text>
        Your insurance card will be sent to you by mail or email directly from
        the carrier, along with your confirmed effective date. You can start
        using it on that date.
      </Text>

      <Button onClick={onNext} rightSection={<IconArrowRight size={16} />}>
        Back to Benefits Summary
      </Button>
    </Stack>
  ) : (
    <Stack gap={24} h="calc(100vh - 64px)" justify="center" align="center">
      <Title order={2}>Selections complete - here are next steps</Title>

      <List>
        <List.Item>
          We'll work with your broker and carriers to review and fulfill your
          benefits selections.
        </List.Item>
        <List.Item>You'll receive a confirmation email shortly.</List.Item>
        <List.Item>
          Keep an eye out for further emails if additional information is
          needed.
        </List.Item>
      </List>
      <Text>
        Once confirmed, your newly selected benefits will appear in{" "}
        {platformName} on{" "}
        {UsMediumDateFormatter.format(Date.parse(enrollment.change_date))}.
      </Text>

      <Button onClick={onNext} rightSection={<IconArrowRight size={16} />}>
        Back to Benefits Summary
      </Button>
    </Stack>
  );
};
