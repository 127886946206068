import { partnerConfigAtom } from "@/stores";
import { Text, Anchor, Checkbox, Stack, Box } from "@mantine/core";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { useEnrollContext } from "../EnrollProvider";

const Pretext =
  "By checking this box, you consent to receive ACA compliance forms electronically";

export const ACAConsentForm = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    state: { acaDigitalFormsEnabled },
    dispatch,
  } = useEnrollContext();

  const { name: platformName } = useAtomValue(partnerConfigAtom[0]);

  return (
    <Box>
      <Checkbox
        mr="xs"
        style={{ float: "left" }}
        checked={acaDigitalFormsEnabled}
        onChange={(e) =>
          dispatch({
            type: "set-aca-digital-forms-enabled",
            payload: e.target.checked,
          })
        }
      />
      <Stack mt={2}>
        {isExpanded && (
          <>
            <Text size="xs" c="gray.7">
              {Pretext}. Your forms will be available on this platform through
              your account and sent to your associated email once they are
              ready.
            </Text>
            <Text size="xs" c="gray.7">
              This consent continues for each reporting year that your current
              employer continues to leverage {platformName} for Affordable Care
              Act compliance. If you do not consent to receiving the statement
              electronically, a paper copy will be provided.
            </Text>
            <Text size="xs" c="gray.7">
              Should you wish to request a paper copy at year end or withdraw
              consent, please contact support, and you will receive confirmation
              of receipt via email (
              <Anchor size="xs" fw="bold" onClick={() => setIsExpanded(false)}>
                see less
              </Anchor>
              )
            </Text>
          </>
        )}

        {!isExpanded && (
          <Text size="xs" c="gray.7">
            {Pretext} (
            <Anchor size="xs" fw="bold" onClick={() => setIsExpanded(true)}>
              see more
            </Anchor>
            )
          </Text>
        )}
      </Stack>
    </Box>
  );
};
